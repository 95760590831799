import React from "react";
import { Form, Popover, Upload } from "antd";
import UploadUtils from "../../utilities/uploadUtils";
import SortDraggableList from "../SortDraggableList";
import { QuestionCircleOutlined } from "@ant-design/icons";

const AboutBannerSort = ({ data, setData, form }) => {
  const bannerItems =
    data?.banner3_images?.map((item, index) => ({
      id: index.toString(),
      ...item
    })) || [];

  const renderBannerContent = (field) => (
    <Form.Item
      label={
        <span>
          Image{" "}
          <Popover placement="right" title="W * H" content="300 x 300">
            <QuestionCircleOutlined />
          </Popover>
        </span>
      }
      name={[field.name, "image"]}
      {...UploadUtils.formItemProps}
      wrapperCol={{ span: 24 }}
    >
      <Upload {...UploadUtils.buttonPreviewProps}>
        <UploadUtils.CardContent />
      </Upload>
    </Form.Item>
  );

  return (
    <SortDraggableList
      items={bannerItems}
      renderContent={renderBannerContent}
      setData={setData}
      form={form}
      formField="banner3_images"
      addContent={true}
      showRemoveOption={true}
      cardTitle="Banner Image"
    />
  );
};

export default AboutBannerSort;
