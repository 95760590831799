import {
  PiUserListFill,
  PiUsersThreeDuotone,
  PiUserSquareDuotone,
  PiKeyDuotone,
  PiShoppingBagDuotone,
  PiGearSixDuotone,
  PiWarehouseDuotone,
  PiStackDuotone,
  PiBasketDuotone,
  PiFileDuotone,
  PiShoppingCartDuotone,
  PiPackageDuotone,
  PiFileArrowDown,
} from "react-icons/pi";
import {
  MdOutlineInventory,
  MdOutlineReviews,
  MdOutlineShoppingCartCheckout,
} from "react-icons/md";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { BiCartDownload } from "react-icons/bi";
import { HiOutlineAdjustments, HiOutlineClipboardList } from "react-icons/hi";
import { LiaShippingFastSolid } from "react-icons/lia";
import routes from "./routes";
import roleAndPermissions from "./permissions";
import { BiSolidDiscount } from "react-icons/bi";

const menus = [
  // {
  //   icon: <MdOutlineInventory />,
  //   label: "Orders",
  //   requiredPermissions: [
  //     roleAndPermissions.Order.create,
  //     roleAndPermissions.Order.update,
  //     roleAndPermissions.Order.delete,
  //     roleAndPermissions.Order.view,
  //   ],
  //   type: "group",
  //   children: [
  //     {
  //       key: routes.ORDERS.path,
  //       icon: <PiShoppingCartDuotone />,
  //       label: "Orders",
  //       requiredPermissions: [
  //         roleAndPermissions.Order.create,
  //         roleAndPermissions.Order.update,
  //         roleAndPermissions.Order.delete,
  //         roleAndPermissions.Order.view,
  //       ],
  //     },
  //     {
  //       key: routes.ORDERS_BATCH.path,
  //       icon: <HiOutlineClipboardList />,
  //       label: "Orders Batch",
  //       requiredPermissions: [
  //         roleAndPermissions.OrdersBatch.create,
  //         roleAndPermissions.OrdersBatch.update,
  //         roleAndPermissions.OrdersBatch.delete,
  //         roleAndPermissions.OrdersBatch.view,
  //       ],
  //     },
  //     {
  //       key: routes.ORDERS_PICKING.path,
  //       icon: <MdOutlineShoppingCartCheckout />,
  //       label: "Orders Picking",
  //       requiredPermissions: [
  //         roleAndPermissions.OrdersPicking.create,
  //         roleAndPermissions.OrdersPicking.update,
  //         roleAndPermissions.OrdersPicking.delete,
  //         roleAndPermissions.OrdersPicking.view,
  //       ],
  //     },
  //     {
  //       key: routes.ORDERS_PACKING.path,
  //       icon: <PiPackageDuotone />,
  //       label: "Orders Packing",
  //       requiredPermissions: [
  //         roleAndPermissions.OrdersPacking.create,
  //         roleAndPermissions.OrdersPacking.update,
  //         roleAndPermissions.OrdersPacking.delete,
  //         roleAndPermissions.OrdersPacking.view,
  //       ],
  //     },
  //     {
  //       key: routes.ORDERS_SHIPPING.path,
  //       icon: <LiaShippingFastSolid />,
  //       label: "Orders Shipping",
  //       requiredPermissions: [
  //         roleAndPermissions.OrdersShipping.create,
  //         roleAndPermissions.OrdersShipping.update,
  //         roleAndPermissions.OrdersShipping.delete,
  //         roleAndPermissions.OrdersShipping.view,
  //       ],
  //     },
  //     {
  //       key: routes.ABANDONEDCHECKOUTS.path,
  //       icon: <BiCartDownload />,
  //       label: "Abandoned Checkouts",
  //       requiredPermissions: [
  //         roleAndPermissions.AbandonedCheckouts.create,
  //         roleAndPermissions.AbandonedCheckouts.update,
  //         roleAndPermissions.AbandonedCheckouts.delete,
  //         roleAndPermissions.AbandonedCheckouts.view,
  //       ],
  //     },
  //   ],
  // },
  {
    label: "Items",
    requiredPermissions: [
      roleAndPermissions.ProductManagement.create,
      roleAndPermissions.ProductManagement.update,
      roleAndPermissions.ProductManagement.delete,
      roleAndPermissions.ProductManagement.view,
    ],
    type: "group",
    children: [
      {
        key: routes.PRODUCTS.path,
        label: "Products",
        icon: <PiShoppingBagDuotone />,
        requiredPermissions: [
          roleAndPermissions.ProductManagement.create,
          roleAndPermissions.ProductManagement.update,
          roleAndPermissions.ProductManagement.delete,
          roleAndPermissions.ProductManagement.view,
        ],
      },
      {
        key: routes.PRODUCTSREVIEWS.path,
        label: "Products Reviews",
        icon: <MdOutlineReviews />,
        requiredPermissions: [
          roleAndPermissions.ProductReviews.create,
          roleAndPermissions.ProductReviews.update,
          roleAndPermissions.ProductReviews.delete,
          roleAndPermissions.ProductReviews.view,
        ],
      },
      {
        key: "product-meta",
        label: "Settings",
        icon: <PiGearSixDuotone />,
        requiredPermissions: [
          roleAndPermissions.ProductManagement.create,
          roleAndPermissions.ProductManagement.update,
          roleAndPermissions.ProductManagement.delete,
          roleAndPermissions.ProductManagement.view,
        ],
        labelOnly: true,
        children: [
          {
            key: routes.PRODUCT_CATEGORY.path,
            label: "Categories",
            requiredPermissions: [
              roleAndPermissions.ProductCategory.create,
              roleAndPermissions.ProductCategory.update,
              roleAndPermissions.ProductCategory.delete,
              roleAndPermissions.ProductCategory.view,
            ],
            openMenuKeys: ["product-meta"],
          },
          {
            key: routes.PRODUCT_CATALOG.path,
            label: "Catalogs",
            requiredPermissions: [
              roleAndPermissions.ProductCatalog.create,
              roleAndPermissions.ProductCatalog.update,
              roleAndPermissions.ProductCatalog.delete,
              roleAndPermissions.ProductCatalog.view,
            ],
            openMenuKeys: ["product-meta"],
          },
          // {
          //   key: routes.PRODUCT_TYPE.path,
          //   label: "Types",
          //   requiredPermissions: [
          //     roleAndPermissions.ProductType.create,
          //     roleAndPermissions.ProductType.update,
          //     roleAndPermissions.ProductType.delete,
          //     roleAndPermissions.ProductType.view,
          //   ],
          //   openMenuKeys: ["product-meta"],
          // },
          // {
          //   key: routes.PRODUCT_ATTRIBUTE.path,
          //   label: "Attributes",
          //   requiredPermissions: [
          //     roleAndPermissions.ProductAttribute.create,
          //     roleAndPermissions.ProductAttribute.update,
          //     roleAndPermissions.ProductAttribute.delete,
          //     roleAndPermissions.ProductAttribute.view,
          //   ],
          //   openMenuKeys: ["product-meta"],
          // },
          {
            key: routes.PRODUCT_BRAND.path,
            label: "Brands",
            requiredPermissions: [
              roleAndPermissions.ProductBrand.create,
              roleAndPermissions.ProductBrand.update,
              roleAndPermissions.ProductBrand.delete,
              roleAndPermissions.ProductBrand.view,
            ],
            openMenuKeys: ["product-meta"],
          },
          {
            key: routes.PRODUCT_COMPATIBILITY.path,
            label: "Compatibility",
            requiredPermissions: [
              roleAndPermissions.ProductCompatibility.create,
              roleAndPermissions.ProductCompatibility.update,
              roleAndPermissions.ProductCompatibility.delete,
              roleAndPermissions.ProductCompatibility.view,
            ],
          },
          {
            key: routes.ORDERS_ADDON.path,
            label: "Add-ons",
            requiredPermissions: [
              roleAndPermissions.OrderAddOn.create,
              roleAndPermissions.OrderAddOn.update,
              roleAndPermissions.OrderAddOn.delete,
              roleAndPermissions.OrderAddOn.view,
            ],
          },
        ],
      },
      {
        key: routes.DISCOUNTS.path,
        label: "Discounts",
        icon: <BiSolidDiscount />,
        requiredPermissions: [
          roleAndPermissions.DiscountManagement.create,
          roleAndPermissions.DiscountManagement.update,
          roleAndPermissions.DiscountManagement.delete,
          roleAndPermissions.DiscountManagement.view,
        ],
      },
    ],
  },
  // {
  //   icon: <MdOutlineInventory />,
  //   label: "Inventory",
  //   requiredPermissions: [
  //     roleAndPermissions.ProductInventory.create,
  //     roleAndPermissions.ProductInventory.update,
  //     roleAndPermissions.ProductInventory.delete,
  //     roleAndPermissions.ProductInventory.view,
  //   ],
  //   type: "group",
  //   children: [
  //     {
  //       key: routes.INVENTORY.path,
  //       icon: <PiStackDuotone />,
  //       label: "Inventory",
  //       requiredPermissions: [
  //         roleAndPermissions.ProductInventory.create,
  //         roleAndPermissions.ProductInventory.update,
  //         roleAndPermissions.ProductInventory.delete,
  //         roleAndPermissions.ProductInventory.view,
  //       ],
  //     },
  //     {
  //       key: routes.WAREHOUSES.path,
  //       label: "Warehouses",
  //       icon: <PiWarehouseDuotone />,
  //       requiredPermissions: [
  //         roleAndPermissions.Warehouse.create,
  //         roleAndPermissions.Warehouse.update,
  //         roleAndPermissions.Warehouse.delete,
  //         roleAndPermissions.Warehouse.view,
  //       ],
  //     },
  //     {
  //       key: routes.ADJUSTMENT.path,
  //       icon: <HiOutlineAdjustments />,
  //       label: "Adjustment",
  //       requiredPermissions: [
  //         roleAndPermissions.InventoryAdjust.create,
  //         roleAndPermissions.InventoryAdjust.update,
  //         roleAndPermissions.InventoryAdjust.delete,
  //         roleAndPermissions.InventoryAdjust.view,
  //       ],
  //     },
  //     {
  //       key: "transfers-meta",
  //       label: "Transfers",
  //       icon: <CgArrowsExchangeAlt />,
  //       requiredPermissions: [
  //         roleAndPermissions.InventoryExternalTransfer.create,
  //         roleAndPermissions.InventoryExternalTransfer.update,
  //         roleAndPermissions.InventoryExternalTransfer.delete,
  //         roleAndPermissions.InventoryExternalTransfer.view,
  //         roleAndPermissions.InventoryInternalTransfer.create,
  //         roleAndPermissions.InventoryInternalTransfer.update,
  //         roleAndPermissions.InventoryInternalTransfer.delete,
  //         roleAndPermissions.InventoryInternalTransfer.view,
  //       ],
  //       labelOnly: true,
  //       children: [
  //         {
  //           key: routes.INTERNAL_TRANSFERS.path,
  //           label: "Internal",
  //           // icon: <CgArrowsExchangeAlt />,
  //           requiredPermissions: [
  //             roleAndPermissions.InventoryInternalTransfer.create,
  //             roleAndPermissions.InventoryInternalTransfer.update,
  //             roleAndPermissions.InventoryInternalTransfer.delete,
  //             roleAndPermissions.InventoryInternalTransfer.view,
  //           ],
  //           openMenuKeys: ["transfers-meta"],
  //         },
  //         {
  //           key: routes.EXTERNAL_TRANSFERS.path,
  //           label: "External",
  //           // icon: <CgArrowsExchangeAlt />,
  //           requiredPermissions: [
  //             roleAndPermissions.InventoryExternalTransfer.create,
  //             roleAndPermissions.InventoryExternalTransfer.update,
  //             roleAndPermissions.InventoryExternalTransfer.delete,
  //             roleAndPermissions.InventoryExternalTransfer.view,
  //           ],
  //           openMenuKeys: ["transfers-meta"],
  //         },
  //       ],
  //     },
  //     // {
  //     //   key: routes.EXTERNALTRANSFERS.path,
  //     //   label: "External Transfers",
  //     //   icon: <CgArrowsExchangeAlt />,
  //     //   requiredPermissions: [
  //     //     roleAndPermissions.InventoryTransfer.create,
  //     //     roleAndPermissions.InventoryTransfer.update,
  //     //     roleAndPermissions.InventoryTransfer.delete,
  //     //     roleAndPermissions.InventoryTransfer.view,
  //     //   ],
  //     // },
  //   ],
  // },
  {
    icon: <PiUserListFill />,
    label: "Purchase",
    requiredPermissions: [
      roleAndPermissions.Vendor.create,
      roleAndPermissions.Vendor.update,
      roleAndPermissions.Vendor.delete,
      roleAndPermissions.Vendor.view,
    ],
    type: "group",
    children: [
      {
        key: routes.VENDORS.path,
        icon: <PiBasketDuotone />,
        label: "Vendors",
        requiredPermissions: [
          roleAndPermissions.Vendor.create,
          roleAndPermissions.Vendor.update,
          roleAndPermissions.Vendor.delete,
          roleAndPermissions.Vendor.view,
        ],
      },
      // {
      //   key: routes.PURCHASE_ORDERS.path,
      //   label: "Purchase Orders",
      //   icon: <PiFileDuotone />,
      //   requiredPermissions: [
      //     roleAndPermissions.PurchaseOrder.create,
      //     roleAndPermissions.PurchaseOrder.update,
      //     roleAndPermissions.PurchaseOrder.delete,
      //     roleAndPermissions.PurchaseOrder.view,
      //   ],
      // },
      // {
      //   key: routes.PURCHASE_RECEIVE.path,
      //   label: "Purchase Receive",
      //   icon: <PiFileArrowDown/>,
      //   requiredPermissions: [
      //     roleAndPermissions.PurchaseOrder.create,
      //     roleAndPermissions.PurchaseOrder.update,
      //     roleAndPermissions.PurchaseOrder.delete,
      //     roleAndPermissions.PurchaseOrder.view,
      //   ],
      // },
    ],
  },
  {
    // key: "user-management-group",
    label: "Sales",
    requiredPermissions: [
      roleAndPermissions.CustomerManagement.view,
      roleAndPermissions.CustomerManagement.create,
      roleAndPermissions.CustomerManagement.update,
      roleAndPermissions.CustomerManagement.delete,
      roleAndPermissions.AbandonedCheckouts.create,
      roleAndPermissions.AbandonedCheckouts.update,
      roleAndPermissions.AbandonedCheckouts.delete,
      roleAndPermissions.AbandonedCheckouts.view,
    ],
    type: "group",
    children: [
      {
        key: routes.CUSTOMERS.path,
        icon: <PiUserSquareDuotone />,
        label: "Customers",
        requiredPermissions: [
          roleAndPermissions.CustomerManagement.view,
          roleAndPermissions.CustomerManagement.create,
          roleAndPermissions.CustomerManagement.update,
          roleAndPermissions.CustomerManagement.delete,
        ],
      },
      {
        key: routes.ORDERS.path,
        icon: <PiShoppingCartDuotone />,
        label: "Orders",
        requiredPermissions: [
          roleAndPermissions.Order.create,
          roleAndPermissions.Order.update,
          roleAndPermissions.Order.delete,
          roleAndPermissions.Order.view,
        ],
      },
      {
        key: routes.ABANDONEDCHECKOUTS.path,
        icon: <BiCartDownload />,
        label: "Abandoned Checkouts",
        requiredPermissions: [
          roleAndPermissions.AbandonedCheckouts.view,
          roleAndPermissions.AbandonedCheckouts.create,
          roleAndPermissions.AbandonedCheckouts.update,
          roleAndPermissions.AbandonedCheckouts.delete,
        ],
      },
      // {
      //   key: routes.ORDERS.path,
      //   icon: <PiShoppingCartDuotone />,
      //   label: "Orders",
      //   requiredPermissions: [
      //     roleAndPermissions.Order.create,
      //     roleAndPermissions.Order.update,
      //     roleAndPermissions.Order.delete,
      //     roleAndPermissions.Order.view,
      //   ],
      // },
      // {
      //   key: routes.ABANDONEDCHECKOUTS.path,
      //   icon: <BiCartDownload />,
      //   label: "Abandoned Checkouts",
      //   requiredPermissions: [
      //     roleAndPermissions.AbandonedCheckouts.view,
      //     roleAndPermissions.AbandonedCheckouts.create,
      //     roleAndPermissions.AbandonedCheckouts.update,
      //     roleAndPermissions.AbandonedCheckouts.delete,
      //   ],
      // },
    ],
  },
  {
    icon: <MdOutlineInventory />,
    label: "CMS",
    requiredPermissions: [
      roleAndPermissions.PageManagement.view,
      roleAndPermissions.PageManagement.create,
      roleAndPermissions.PageManagement.update,
      roleAndPermissions.PageManagement.delete,
    ],
    type: "group",
    children: [
      {
        key: routes.CMSPAGE.path,
        // icon: <PiStackDuotone />,
        label: "CMS Pages",
        requiredPermissions: [
          roleAndPermissions.PageManagement.view,
          roleAndPermissions.PageManagement.create,
          roleAndPermissions.PageManagement.update,
          roleAndPermissions.PageManagement.delete,
        ],
      },
      {
        key: routes.BLOGS.path,
        // icon: <PiStackDuotone />,
        label: "Blogs",
        requiredPermissions: [
          roleAndPermissions.PageManagement.view,
          roleAndPermissions.PageManagement.create,
          roleAndPermissions.PageManagement.update,
          roleAndPermissions.PageManagement.delete,
        ],
      },
    ],
  },
];

export default menus;
