import React from "react";
import { Form, Input, Popover, Upload } from "antd";
import UploadUtils from "../../utilities/uploadUtils";
import SortDraggableList from "../SortDraggableList";
import { QuestionCircleOutlined } from "@ant-design/icons";

const OEMInfoSolutionSort = ({ data, setData, form }) => {
  const items =
    data?.oem_info_solutions?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderContent = (field) => (
    <div>
      <Form.Item
        label="Name"
        name={[field.name, "name"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={
          <span>
            Image{" "}
            <Popover placement="right" title="W * H" content="60 x 60">
              <QuestionCircleOutlined />
            </Popover>
          </span>
        }
        name={[field.name, "image"]}
        {...UploadUtils.formItemProps}
        wrapperCol={{ span: 24 }}
      >
        <Upload {...UploadUtils.buttonPreviewProps}>
          <UploadUtils.CardContent />
        </Upload>
      </Form.Item>
    </div>
  );

  return (
    <SortDraggableList
      items={items}
      renderContent={renderContent}
      setData={setData}
      form={form}
      formField="oem_info_solutions"
      addContent={false}
      showRemoveOption={false}
      cardTitle="OEM Info Solution"
    />
  );
};

export default OEMInfoSolutionSort;
