import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

apiInstance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.status === "Error") {
        if(response.data.data !== null){
          message.error(response.data.message);
        }
        return Promise.reject(response.data.message);
      }
      return response.data;
    }
  },
  (error) => {
    if (error?.response?.status === 422) {
      return Promise.reject(error.response.data);
    } else if (error?.response?.status === 501) {
      message.warning(error.response.data.message);
    } else if (error?.response?.data?.message) {
      message.error(error.response.data.message);
    } else {
      message.error(error.message);
    }
    if (error?.response?.status === 403) {
      Cookies.remove("infotainment-token");
      // setTimeout(() => {
      //   // window.location.href = '/';
      //       // window.history.go(-1);
      //       // window.history.go(-1);
      //   // window.location.reload();
      // }, 2000);
    }
    return Promise.reject(error);
  }
);

function requests(external_token) {
  let token = external_token ?? Cookies.get("infotainment-token");
  let get = (url, data, withAuth = true, config = {}) => {
    if (withAuth) {
      config = {
        headers: {
          Authorization: "Bearer " + token,
        },
        ...config,
      };
    }
    return apiInstance.get(url, { ...config, params: { ...data } });
  };

  const post = (url, data, withAuth = true) => {
    let config = {};
    if (withAuth) {
      config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
    }
    return apiInstance.post(url, data, config);
  };

  const put = (url, data, withAuth = true) => {
    let config = {};
    if (withAuth) {
      config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
    }
    return apiInstance.put(url, data, config);
  };

  const del = (url, data, withAuth = true) => {
    let config = {};
    if (withAuth) {
      config = {
        headers: {
          Authorization: "Bearer " + token,
        },
        data: data,
      };
    }
    return apiInstance.delete(url, config);
  };

  const api = { get, post, put, del };
  return api;
}
export default requests;
