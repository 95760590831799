import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  DatePicker,
  InputNumber,
  Upload,
  Popover,
} from "antd";
import PageHeader from "../../../components/pageHeader";
import UploadUtils from "../../../utilities/uploadUtils";
import SortDraggableList from "../../../components/SortDraggableList";
import ReactQuill from "react-quill";
import dayjs from "dayjs";

const SortableCoreRefundPolicy = ({ data, setData, form }) => {
  const coreRefundPolicyItem =
    data?.corerefundpolicy?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderCoreRefundPolicyContent = (field) => (
    <div>
      <Form.Item
        label="Title"
        name={[field.name, "name"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Core Refund & Policy Description"
        name={[field.name, "description"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <ReactQuill theme="snow" rows={4} />
      </Form.Item>
    </div>
  );

  return (
    <SortDraggableList
      items={coreRefundPolicyItem}
      renderContent={renderCoreRefundPolicyContent}
      setData={setData}
      form={form}
      formField="corerefundpolicy"
      addContent={true}
      showRemoveOption={true}
      cardTitle="Core Refund & Policy"
    />
  );
};

const CoreRefundPolicy = ({ mode, formFor, editLayout }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(formFor?.en?.data);

  const handleSubmit = (values) => {
    // console.log(values);
    try {
      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
        },
      ];

      setData(values);
      editLayout(values, "Core Refund Policy", seo_setting);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form
      form={form}
      disabled={mode === "View"}
      layout="vertical"
      initialValues={data}
      onFinish={(values) => handleSubmit(values)}
    >
      <PageHeader title={`${formFor?.title?.replace("-", " ")} Page`} />
      <Row gutter={16} className="mt30">

      <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Core Refund & Policy Main Title">
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
             <Input />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Core Refund & Policy Section">
            <SortableCoreRefundPolicy
              data={data}
              setData={setData}
              form={form}
            />
          </Card>
        </Col>

        {/* Search Engine Listing */}
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Search Engine Listing">
            <Form.Item
              label="Meta Title"
              name="meta_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Meta Description"
              name="meta_description"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <ReactQuill theme="snow" rows={4} />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24}>
          <Flex justify="end" style={{ padding: 24,paddingRight:0 }} gap={16}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Page
              </Button>
            </Form.Item>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
};

export default CoreRefundPolicy;
