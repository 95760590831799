import React from "react";
import CMSlist from "../pages/cms-pages/CMSlist";
import { lazy } from "react";
import DiscountList from "../pages/product-management/Discounts/List";
import DiscountAdd from "../pages/product-management/Discounts/Add";
import DiscountEdit from "../pages/product-management/Discounts/Edit";
const ForgotPassword = lazy(() => import("../pages/forgot-password"));
const Login = lazy(() => import("../pages/login"));
const LoginOld = lazy(() => import("../pages/login_old"));
const PasswordReset = lazy(() => import("../pages/password-reset"));
const Users = lazy(() => import("../pages/user-management/admin-user/list"));
const AddUser = lazy(() => import("../pages/user-management/admin-user/add"));
const AccessManagement = lazy(() => import("../pages/access-management/list"));
const EmailTemplate = lazy(() => import("../pages/email-template/list"));
const UpdateProfile = lazy(() => import("../pages/user-profile/update"));
const Customers = lazy(() => import("../pages/user-management/customer/list"));
const ViewCustomer = lazy(() =>
  import("../pages/user-management/customer/view")
);
const AddCustomer = lazy(() => import("../pages/user-management/customer/add"));
const ReactiveAccount = lazy(() => import("../pages/reactive-account"));
const ProductAttribute = lazy(() =>
  import("../pages/product-management/product-attribute/list")
);
const AddAttribute = lazy(() =>
  import("../pages/product-management/product-attribute/add")
);
const ProductsReviews = lazy(() =>
  import("../pages/product-management/reviews/list")
);
const ViewReview = lazy(() =>
  import("../pages/product-management/reviews/view")
);
const ProductCategory = lazy(() =>
  import("../pages/product-management/product-category/list")
);
const AddCategory = lazy(() =>
  import("../pages/product-management/product-category/add")
);
const ProductCollection = lazy(() =>
  import("../pages/product-management/product-collection/list")
);
const AddCollection = lazy(() =>
  import("../pages/product-management/product-collection/add")
);
const Products = lazy(() => import("../pages/product-management/product/list"));
const AddProduct = lazy(() =>
  import("../pages/product-management/product/add")
);
const EditHeader = lazy(() => import("../CMS/header/Edit"));
const EditFooter = lazy(() => import("../CMS/footer/Edit"));
const EditProductPage = lazy(() => import("../CMS/product/Edit"));
const EditCategoriesPage = lazy(() => import("../CMS/categories/Edit"));
const EditCMSPage = lazy(() => import("../CMS/pages/Edit"));
const EditCMSBlogPage = lazy(() => import("../CMS/blog-pages/Edit"));
const EditSystemPage = lazy(() => import("../CMS/system-pages/Edit"));
const ComponentsTab = lazy(() => import("../pages/cms-pages/ComponentsTab"));
const ProductBrand = lazy(() =>
  import("../pages/product-management/product-brand/list")
);
const AddBrand = lazy(() =>
  import("../pages/product-management/product-brand/add")
);
const ViewBrand = lazy(() =>
  import("../pages/product-management/product-brand/view")
);
const HeaderSetting = lazy(() =>
  import("../pages/setting/header-setting/list")
);
const ProductCompatibility = lazy(() =>
  import("../pages/product-management/compatibility/list")
);
const AddCompatibility = lazy(() =>
  import("../pages/product-management/compatibility/add")
);

const AbandonedCheckouts = lazy(() =>
  import("../pages/orders-management/abandoned-checkouts/list")
);

const ViewCheckout = lazy(() =>
  import("../pages/orders-management/abandoned-checkouts/view")
);

const Orders = lazy(() => import("../pages/orders-management/orders/list"));
const AddOrder = lazy(() => import("../pages/orders-management/orders/add"));
const ViewOrder = lazy(() => import("../pages/orders-management/orders/view"));
const EditOrder = lazy(() => import("../pages/orders-management/orders/add"));
const AddBlog = lazy(() => import("../pages/cms-pages/blogs/add"));
const Blog = lazy(() => import("../pages/cms-pages/blogs/blog"));

const AddStore = lazy(() => import("../pages/store/store-address/add"));
const AddShipStation = lazy(() =>
  import("../pages/store/shipstation-shipping/add")
);
const AddVendor = lazy(() => import("../pages/purchase/vendor/add"));
const Vendor = lazy(() => import("../pages/purchase/vendor/list"));
const ViewVendor = lazy(() => import("../pages/purchase/vendor/view"));
const SalesTeam = lazy(() =>
  import("../pages/orders-management/sales-team/list")
);

const OrderAddOn = lazy(() =>
  import("../pages/orders-management/ordor-addon/list")
);
const AddOrderAddOn = lazy(() =>
  import("../pages/orders-management/ordor-addon/add")
);
const Test = lazy(() => import("../pages/test"));

const routes = {
  LOGIN: {
    path: "/login",
    component: Login,
    requireLogin: false,
    allowAccessWhenLoggedIn: false,
    pageTitle: "Login",
  },
  LOGIN_OLD: {
    path: "/login-old",
    component: LoginOld,
    requireLogin: false,
    allowAccessWhenLoggedIn: false,
    pageTitle: "Login Old",
  },
  DASHBOARD: {
    path: "/",
    component: React.Fragment,
    withSidebar: true,
    pageTitle: "Dashboard",
  },
  RESET_PASSWORD: {
    path: "/password-reset",
    component: PasswordReset,
    withSidebar: false,
    requireLogin: false,
    pageTitle: "Reset Password",
  },
  FORGOT_PASSWORD: {
    path: "/forgot-password",
    component: ForgotPassword,
    withSidebar: false,
    requireLogin: false,
    pageTitle: "Forgot Password",
  },
  REACTIVE_ACCOUNT: {
    path: "/reactive-account/:reactive_token",
    component: ReactiveAccount,
    withSidebar: false,
    requireLogin: false,
    pageTitle: "Reactive Account",
  },
  USERS: {
    path: "/users",
    component: Users,
    withSidebar: true,
    pageTitle: "Users",
  },
  ADD_USER: {
    path: "/add-user",
    component: AddUser,
    withSidebar: true,
    pageTitle: "Add User",
  },
  EDIT_USER: {
    path: "/edit-user/:id",
    component: AddUser,
    withSidebar: true,
    pageTitle: "Edit User",
  },
  ACCESS_MANAGEMENT: {
    path: "/roles-and-permissions",
    component: AccessManagement,
    withSidebar: true,
    pageTitle: "Access Control",
  },
  PROFILE_UPDATE: {
    path: "/update-profile",
    component: UpdateProfile,
    withSidebar: true,
    pageTitle: "Update Profile",
  },
  CUSTOMERS: {
    path: "/customers",
    component: Customers,
    withSidebar: true,
    pageTitle: "Customers",
  },
  ADD_CUSTOMER: {
    path: "/add-customer",
    component: AddCustomer,
    withSidebar: true,
    pageTitle: "Add Customer",
  },
  EDIT_CUSTOMER: {
    path: "/edit-customer/:id",
    component: AddCustomer,
    withSidebar: true,
    pageTitle: "Edit Customer",
  },
  VIEW_CUSTOMER: {
    path: "/view-customer/:id",
    component: ViewCustomer,
    withSidebar: true,
    pageTitle: "View Customer",
  },
  PRODUCTS: {
    path: "/products",
    component: Products,
    withSidebar: true,
    pageTitle: "Products",
  },
  ADD_PRODUCT: {
    path: "/add-product",
    component: AddProduct,
    withSidebar: true,
    pageTitle: "Add Product",
  },
  ORDERS: {
    path: "/orders",
    component: Orders,
    withSidebar: true,
    pageTitle: "Orders",
  },
  ADD_ORDER: {
    path: "/add-order",
    component: AddOrder,
    withSidebar: true,
    pageTitle: "Add Orders",
  },
  EDIT_ORDER: {
    path: "/edit-order/:id",
    component: AddOrder,
    withSidebar: true,
    pageTitle: "Edit Orders",
  },
  VIEW_ORDER: {
    path: "/view-order/:id",
    component: ViewOrder,
    withSidebar: true,
    pageTitle: "View Order",
  },
  EDIT_PRODUCT: {
    path: "/edit-product/:id",
    component: AddProduct,
    withSidebar: true,
    pageTitle: "Edit Product",
  },
  PRODUCT_ATTRIBUTE: {
    path: "/product-attribute",
    component: ProductAttribute,
    withSidebar: true,
    pageTitle: "Product Attribute",
  },
  ADD_ATTRIBUTE: {
    path: "/add-attribute",
    component: AddAttribute,
    withSidebar: true,
    pageTitle: "Add Attribute",
  },
  EDIT_ATTRIBUTE: {
    path: "/edit-attribute/:id",
    component: AddAttribute,
    withSidebar: true,
    pageTitle: "Edit Attribute",
  },
  PRODUCT_CATEGORY: {
    path: "/product-category",
    component: ProductCategory,
    withSidebar: true,
    pageTitle: "Product Category",
  },
  ADD_CATEGORY: {
    path: "/add-category",
    component: AddCategory,
    withSidebar: true,
    pageTitle: "Add Category",
  },
  EDIT_CATEGORY: {
    path: "/edit-category/:id",
    component: AddCategory,
    withSidebar: true,
    pageTitle: "Edit Category",
  },
  PRODUCT_CATALOG: {
    path: "/product-catalog",
    component: ProductCollection,
    withSidebar: true,
    pageTitle: "Product Catalog",
  },
  ADD_COLLECTION: {
    path: "/add-catalog",
    component: AddCollection,
    withSidebar: true,
    pageTitle: "Add Catalog",
  },
  EDIT_COLLECTION: {
    path: "/edit-catalog/:id",
    component: AddCollection,
    withSidebar: true,
    pageTitle: "Edit Catalog",
  },
  PRODUCT_BRAND: {
    path: "/product-brand",
    component: ProductBrand,
    withSidebar: true,
    pageTitle: "Product Brand",
  },
  ADD_BRAND: {
    path: "/add-brand",
    component: AddBrand,
    withSidebar: true,
    pageTitle: "Add Brand",
  },
  EDIT_BRAND: {
    path: "/edit-brand/:id",
    component: AddBrand,
    withSidebar: true,
    pageTitle: "Edit Brand",
  },
  SHOW_BRAND: {
    path: "/view-brand/:id",
    component: ViewBrand,
    withSidebar: true,
    pageTitle: "Brand Details",
  },
  PRODUCT_COMPATIBILITY: {
    path: "/product-compatibility",
    component: ProductCompatibility,
    withSidebar: true,
    pageTitle: "Product Compatibility",
  },
  ADD_COMPATIBILITY: {
    path: "/add-compatibility",
    component: AddCompatibility,
    withSidebar: true,
    pageTitle: "Add Compatibility",
  },
  EDIT_COMPATIBILITY: {
    path: "/edit-compatibility/:id",
    component: AddCompatibility,
    withSidebar: true,
    pageTitle: "Edit Compatibility",
  },
  HEADER_SETTING: {
    path: "/settings",
    component: HeaderSetting,
    withSidebar: true,
    pageTitle: "Settings",
  },
  PRODUCTSREVIEWS: {
    path: "/products-reviews",
    component: ProductsReviews,
    withSidebar: true,
    pageTitle: "Products Reviews",
  },
  VIEW_PRODUCTREVIEW: {
    path: "/view-review/:id",
    component: ViewReview,
    withSidebar: true,
    pageTitle: "View Review",
  },
  CMSPAGE: {
    path: "/cms-pages",
    component: CMSlist,
    withSidebar: true,
    pageTitle: "CMS Pages",
  },
  EDIT_HEADER: {
    path: "/edit-header/:id",
    component: EditHeader,
    withSidebar: false,
    pageTitle: "Edit Header",
  },
  EDIT_FOOTER: {
    path: "/edit-footer/:id",
    component: EditFooter,
    withSidebar: false,
    pageTitle: "Edit Footer",
  },
  EDIT_PRODUCT_PAGE: {
    path: "/edit-product-page/:id",
    component: EditProductPage,
    withSidebar: false,
    pageTitle: "Edit Product",
  },
  EDIT_CATEGORY_PAGE: {
    path: "/edit-category-page/:id",
    component: EditCategoriesPage,
    withSidebar: false,
    pageTitle: "Edit Category Page",
  },
  EDIT_PAGE: {
    path: "/edit-pages/:id",
    component: EditCMSPage,
    withSidebar: false,
    pageTitle: "Edit Pages",
  },
  EDIT_SYSTEMPAGE_PAGES: {
    path: "/edit-system-pages/:id",
    component: EditSystemPage,
    withSidebar: false,
    pageTitle: "Edit System Pages",
  },
  EDIT_CMS_BLOG_PAGES: {
    path: "/edit-blog-pages/:id",
    component: EditCMSBlogPage,
    withSidebar: false,
    pageTitle: "Edit Blog Pages",
  },
  ABANDONEDCHECKOUTS: {
    path: "/abandoned-checkouts",
    component: AbandonedCheckouts,
    withSidebar: true,
    pageTitle: "Abandoned Checkouts",
  },
  VIEW_ABANDONEDCHECKOUTS: {
    path: "/abandoned-checkouts/:id",
    component: ViewCheckout,
    withSidebar: true,
    pageTitle: "View Abandoned Checkout",
  },
  DISCOUNTS: {
    path: "/discounts",
    component: DiscountList,
    withSidebar: true,
    pageTitle: "Discounts",
  },
  ADD_DISCOUNT: {
    path: "/add-discount",
    component: DiscountAdd,
    withSidebar: true,
    pageTitle: "Add New Discount",
  },
  BLOGS: {
    path: "/blog",
    component: Blog,
    withSidebar: true,
    pageTitle: "Blogs",
  },
  ADDBLOG: {
    path: "/add-blog",
    component: AddBlog,
    withSidebar: true,
    pageTitle: "Add Blog",
  },
  EDITBLOG: {
    path: "/edit-blog/:id",
    component: AddBlog,
    withSidebar: true,
    pageTitle: "Edit Blog",
  },
  EDIT_DISCOUNT: {
    path: "/edit-discount/:id",
    component: DiscountEdit,
    withSidebar: true,
    pageTitle: "Edit Discount",
  },
  EDIT_STORE: {
    path: "/edit-store-address/:id",
    component: AddStore,
    withSidebar: true,
    pageTitle: "Edit Store Address",
  },
  EDIT_SHIP_STATION: {
    path: "/edit-shipping-zone",
    component: AddShipStation,
    withSidebar: true,
    pageTitle: "Edit Shipping Zone",
  },
  VENDORS: {
    path: "/vendors",
    component: Vendor,
    withSidebar: true,
    pageTitle: "Vendors",
  },
  ADD_VENDOR: {
    path: "/add-vendor",
    component: AddVendor,
    withSidebar: true,
    pageTitle: "Add Vendor",
  },
  EDIT_VENDOR: {
    path: "/edit-vendor/:id",
    component: AddVendor,
    withSidebar: true,
    pageTitle: "Edit Vendor",
  },
  VIEW_VENDOR: {
    path: "/view-vendor/:id",
    component: ViewVendor,
    withSidebar: true,
    pageTitle: "View Vendor",
  },
  SALES_TEAM: {
    path: "/sales-team",
    component: SalesTeam,
    withSidebar: true,
    pageTitle: "Sales Team",
  },
  EMAIL_TEMPLATE: {
    path: "/email-template",
    component: EmailTemplate,
    withSidebar: true,
    pageTitle: "Email Template",
  },
  ORDERS_ADDON: {
    path: "/order-addon",
    component: OrderAddOn,
    withSidebar: true,
    pageTitle: "Orders AddOn",
  },
  ADD_ORDERS_ADDON: {
    path: "/add-order-addon",
    component: AddOrderAddOn,
    withSidebar: true,
    pageTitle: "Add Orders AddOn",
  },
  EDIT_ORDERS_ADDON: {
    path: "/edit-order-addon/:id",
    component: AddOrderAddOn,
    withSidebar: true,
    pageTitle: "Edit Orders AddOn",
  },
  TEST: {
    path: "/test",
    component: Test,
  },
};

export default routes;
