import React from "react";
import { Form, Input } from "antd";
import SortDraggableList from "../SortDraggableList";

const SortableJeepBuildBenefit = ({ data, setData, form }) => {
  const benefitItems =
    data?.jeep_build_benefits?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderBenefitContent = (field) => (
    <Form.Item
      label="Benefit Name"
      name={[field.name, "benefit_name"]}
      rules={[
        {
          required: true,
          message: "This field is required",
        },
      ]}
    >
      <Input />
    </Form.Item>
  );

  return (
    <SortDraggableList
      items={benefitItems}
      renderContent={renderBenefitContent}
      setData={setData}
      form={form}
      formField="jeep_build_benefits"
      addContent={false}
      showRemoveOption={false}
      cardTitle="Benefit"
    />
  );
};

export default SortableJeepBuildBenefit;
