import React from "react";
import PageListing from "../../../components/pageListing";
import DiscountSetting from "../../../config/settings/discounts";
import roleAndPermission from "../../../constants/permissions";
import routes from "../../../constants/routes";
import { useState } from "react";
import { Modal, Button } from "antd";
import { discount_type } from "../../../constants/discountType";
import { MdOutlineNavigateNext } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import endpoints from "../../../constants/endpoints";

const DiscountList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <PageListing
        pageHeader={{
          title: "Discounts",
          addButton: true,
          addButtonLabel: "Add Discount",
        }}
        listingOptions={{
          showFilter: true,
          showSorter: true,
          showExport: true,
        }}
        listingSettings={DiscountSetting}
        endpoints={{ getListing: endpoints.get_discounts }}
        routes={{ add: routes.EDIT_DISCOUNT.path }}
        permissions={{
          view: roleAndPermission.DiscountManagement.view,
          create: roleAndPermission.DiscountManagement.create,
          export: roleAndPermission.DiscountManagement.exportData
        }}
        showDiscountModal={showModal}
      />
      <Modal
        title="Select Discount"
        open={isModalOpen}
        // onOk={handleOk}

        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
        onCancel={handleCancel}
        width={700}
      >
        {discount_type.map((item, index) => (
          <button
            onClick={() =>
              navigate(
                routes.ADD_DISCOUNT.path + `?name=${item.name}&for=${item.for}`
              )
            }
            key={item.name}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: "10px",
              justifyContent: "space-between",
              alignItems: "center",
              background: "white",
              outline: "none",
              border: "none",
              cursor: "pointer",
              borderTop: index === 0 ? "none" : "1px solid #ccc",
              borderBottom:
                index === discount_type.length - 1 ? "1px solid #ccc" : "none", // Adding border-bottom only to the last button
              transition: "background-color 0.3s, border-color 0.3s", // Smooth transition for hover effect
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#f0f0f0"; // Change background on hover
              e.currentTarget.style.borderColor = "#999"; // Change border color on hover
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "white"; // Reset background on leave
              e.currentTarget.style.borderColor = "#ccc"; // Reset border color on leave
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <strong>{item.name}</strong>
              <span>{item.description}</span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                }}
              >
                {item.icons}
                <span>{item.for}</span>
              </div>
              <MdOutlineNavigateNext />
            </div>
          </button>
        ))}
      </Modal>
    </>
  );
};

export default DiscountList;
