import React, { useEffect, useState } from "react";
import {
  Form,
  Card,
  Col,
  Row,
  Input,
  Flex,
  Button,
  Select,
  Upload,
  Popover,
} from "antd";
import PageHeader from "../../../components/pageHeader";
import Dragger from "antd/es/upload/Dragger";
import UploadUtils from "../../../utilities/uploadUtils";
import { MinusCircleOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import requests from "../../../utilities/api";
import endpoints from "../../../constants/endpoints";
import FormItem from "antd/es/form/FormItem";
import HeaderDraggableSort from "../../../components/HeaderDraggableSort";

const HeaderForm = ({ mode, formFor, editLayout }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(formFor?.en?.data);
  const [loading, setloading] = useState(false);
  const [collection, setCollection] = useState([]);
  const [categories, setCategories] = useState([]);

  const getCollection = async () => {
    try {
      setloading(true);
      const api = requests();
      const response = await api.get(
        endpoints.get_collection,
        { limit: -1 },
        true
      );

      setCollection(
        response?.data?.data?.map((item) => ({
          label: item.name,
          // value: item.slug,
          value: item.name,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  const getCategories = async () => {
    try {
      setloading(true);
      const api = requests();
      const response = await api.get(
        endpoints.get_product_category,
        { limit: -1 },
        true
      );

      setCategories(
        response?.data?.data?.map((item) => ({
          label: item.name,
          value: item.slug,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  const handleSubmit = (values) => {
    try {
      values.feature_image = UploadUtils.processUploadImage(
        values.feature_image
      );
      const seo_setting = [
        {
          id: formFor.seo_setting?.[0]?.id,
          meta_title: values.meta_title,
          meta_description: values.meta_description,
          feature_image: UploadUtils.processUploadImage(values.feature_image),
        },
      ];
      setData(data);
      editLayout(values, "Header", seo_setting);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCollection();
    getCategories();
  }, [formFor]);

  return (
    <Form
      form={form}
      disabled={mode === "View"}
      layout="vertical"
      initialValues={data}
      onFinish={(values) => handleSubmit(values)}
    >
      <PageHeader title={`${formFor?.title}`} />
      <Row gutter={16} className="mt30">
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Customer Care">
            <Form.Item
              label="Customer Care Number"
              name="phone_number"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Card>
        </Col>

        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Header Items">
            <HeaderDraggableSort data={data} setData={setData} form={form} />
            {/* <Form.List name="items">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    gap: 30,
                    flexDirection: "column",
                  }}
                >
                  <Row gutter={[16, 16]}>
                    {fields.map((field, index) => (
                      <Col span={24}>
                        <Card
                          size="small"
                          title={`Item ${index + 1}`}
                          key={field.key}
                          extra={
                            <MinusCircleOutlined
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          }
                        >
                          <Row gutter={16} style={{ margin: 0 }}>
                            <Col span={12}>
                              <Form.Item
                                label="Item Name"
                                name={[field.name, "item_name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required",
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label="Item Type"
                                name={[field.name, "item_type"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "This field is required",
                                  },
                                ]}
                              >
                                <Select
                                  options={[
                                    {
                                      value: "link",
                                      label: "Link",
                                    },
                                    {
                                      value: "dropdown",
                                      label: "Dropdown",
                                    },
                                  ]}
                                />
                              </Form.Item>
                            </Col>
                            <Form.Item
                              shouldUpdate={(prevValues, currentValues) =>
                                prevValues.items?.[index]?.item_type !==
                                currentValues.items?.[index]?.item_type
                              }
                              noStyle
                              //   dependencies={["item_type"]}
                            >
                              {({ getFieldValue }) => {
                                let type = getFieldValue([
                                  "items",
                                  index,
                                  "item_type",
                                ]);

                                return (
                                  <>
                                    {type === "link" && (
                                      <>
                                        <Col span={12}>
                                          <Form.Item
                                            label={"Select Link Type"}
                                            name={[field.name, "link_type"]}
                                          >
                                            <Select
                                              options={[
                                                {
                                                  value: "internal",
                                                  label: "Internal",
                                                },
                                                {
                                                  value: "external",
                                                  label: "External",
                                                },
                                              ]}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                          <Form.Item
                                            shouldUpdate={(
                                              prevValues,
                                              currentValues
                                            ) =>
                                              prevValues.items?.[index]
                                                ?.link_type !==
                                              currentValues.items?.[index]
                                                ?.link_type
                                            }
                                            noStyle
                                          >
                                            {({ getFieldValue }) => {
                                              let type = getFieldValue([
                                                "items",
                                                index,
                                                "link_type",
                                              ]);
                                              return (
                                                <>
                                                  {type === "external" && (
                                                    <Form.Item
                                                      label={"Link Url"}
                                                      name={[
                                                        field.name,
                                                        "redirect_path",
                                                      ]}
                                                    >
                                                      <Input />
                                                    </Form.Item>
                                                  )}
                                                  {type === "internal" && (
                                                    <>
                                                      <Form.Item
                                                        label={"Link Url"}
                                                        name={[
                                                          field.name,
                                                          "redirect_path",
                                                        ]}
                                                      >
                                                        <Input addonBefore={process.env.REACT_APP_CUSTOMER_SITE_URL} />
                                                      </Form.Item>
                                                    </>
                                                  )}
                                                </>
                                              );
                                            }}
                                          </Form.Item>
                                        </Col>
                                      </>
                                    )}
                                    {type === "dropdown" && (
                                      <>
                                      
                                        <Form.List
                                          name={[field.name, "options"]}
                                          initialValue={[1]}
                                        >
                                          {(innerfields, { add, remove }) => (
                                            <>
                                             
                                              {innerfields.map(
                                                (innerfield, indexNew) => (
                                                  <Row
                                                    style={{ margin: 0 }}
                                                    gutter={12}
                                                  >
                                                    <Col span={6}>
                                                      <Form.Item
                                                        label={
                                                          indexNew === 0
                                                            ? "Label"
                                                            : ""
                                                        }
                                                        name={[
                                                          innerfield.name,
                                                          "label",
                                                        ]}
                                                        rules={[
                                                          {
                                                            required: true,
                                                            message:
                                                              "This field is required",
                                                          },
                                                        ]}
                                                      >
                                                        <Input />
                                                      </Form.Item>
                                                    </Col>
                                                    <Col span={4}>
                                                      <Form.Item
                                                        // label={"Select Link Type"}
                                                        label={
                                                          indexNew === 0
                                                            ? "Link Type"
                                                            : ""
                                                        }
                                                        name={[
                                                          innerfield.name,
                                                          "link_type",
                                                        ]}
                                                      >
                                                        <Select
                                                          options={[
                                                            {
                                                              value: "internal",
                                                              label: "Internal",
                                                            },
                                                            {
                                                              value: "external",
                                                              label: "External",
                                                            },
                                                          ]}
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                      <Form.Item
                                                        shouldUpdate={(
                                                          prevValues,
                                                          currentValues
                                                        ) =>
                                                          prevValues.items?.[
                                                            index
                                                          ]?.options?.[indexNew]
                                                            ?.link_type !==
                                                          currentValues.items?.[
                                                            index
                                                          ]?.options?.[indexNew]
                                                            ?.link_type
                                                        }
                                                        noStyle
                                                      >
                                                        {({
                                                          getFieldValue,
                                                        }) => {
                                                          let type =
                                                            getFieldValue([
                                                              "items",
                                                              index,
                                                              "options",
                                                              indexNew,
                                                              "link_type",
                                                            ]);
                                                          return (
                                                            <>
                                                              {type ===
                                                                "external" && (
                                                                <Form.Item
                                                                  // label={"Link Url"}
                                                                  label={
                                                                    indexNew ===
                                                                    0
                                                                      ? "Link Url"
                                                                      : ""
                                                                  }
                                                                  name={[
                                                                    innerfield.name,
                                                                    "url",
                                                                  ]}
                                                                >
                                                                  <Input />
                                                                </Form.Item>
                                                              )}
                                                              {type ===
                                                                "internal" && (
                                                                <>
                                                                  <Form.Item
                                                                    // label={"Link Url"}
                                                                    label={
                                                                      indexNew ===
                                                                      0
                                                                        ? "Link Url"
                                                                        : ""
                                                                    }
                                                                    name={[
                                                                      innerfield.name,
                                                                      "url",
                                                                    ]}
                                                                  >
                                                                   <Input addonBefore={process.env.REACT_APP_CUSTOMER_SITE_URL} />
                                                                  </Form.Item>
                                                                </>
                                                              )}
                                                            </>
                                                          );
                                                        }}
                                                      </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                      <div
                                                        style={{
                                                          marginTop:
                                                            indexNew === 0
                                                              ? 36
                                                              : 4,
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                      >
                                                        <MinusCircleOutlined
                                                          onClick={() => {
                                                            remove(
                                                              innerfield.name
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                )
                                              )}
                                              <Col span={24}>
                                                <Form.Item>
                                                  <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    block
                                                    icon={<PlusOutlined />}
                                                  >
                                                    Add Options
                                                  </Button>
                                                </Form.Item>
                                              </Col>
                                            </>
                                          )}
                                        </Form.List>
                                      </>
                                    )}
                                  </>
                                );
                              }}
                            </Form.Item>
                            
                          </Row>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  {fields.length < 6 && (
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Header Item
                      </Button>
                    </Form.Item>
                  )}
                </div>
              )}
            </Form.List> */}
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <Card size="small" title="Search Engine Listing">
            <Form.Item
              label="Meta Title"
              name="meta_title"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Meta Description"
              name="meta_description"
              rules={[
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={
                <span>
                  Feature Image{" "}
                  <Popover
                    placement="right"
                    title="W * H"
                    content="1200 x 675 OR 1600 x 900"
                  >
                    <QuestionCircleOutlined />
                  </Popover>
                </span>
              }
              name="feature_image"
              {...UploadUtils.formItemProps}
              wrapperCol={{ span: 24 }}
            >
              <Upload {...UploadUtils.buttonPreviewProps}>
                <UploadUtils.CardContent />
              </Upload>
            </Form.Item>
          </Card>
        </Col>
        <Col span={24}>
          <Flex justify="end" style={{ padding: 24,paddingRight:0 }} gap={16}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Page
              </Button>
            </Form.Item>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
};

export default HeaderForm;
