import HomePageForm from "../pages/cms-pages/pages-form/HomePageForm";
import AboutUsPageForm from "../pages/cms-pages/pages-form/AboutUsPageForm";
import HeaderForm from "../pages/cms-pages/pages-form/HeaderForm";
import FooterForm from "../pages/cms-pages/pages-form/FooterForm";
import TermConditionsForm from "../pages/cms-pages/pages-form/TermConditionsForm";
import PrivacyPolicyForm from "../pages/cms-pages/pages-form/PrivacyPolicyForm";
import ContactUsForm from "../pages/cms-pages/pages-form/ContactUsForm";
import VerifiedInstallerForm from "../pages/cms-pages/pages-form/VerifiedInstallerForm";
import ForWholeSalerForms from "../pages/cms-pages/pages-form/ForWholeSalerForm";
import CoreRefundPolicy from "../pages/cms-pages/pages-form/CoreRefundPolicy";
import WarrantyTermsForm from "../pages/cms-pages/pages-form/WarrantyTermsForm";
import LicensingComplianceForm from "../pages/cms-pages/pages-form/LicensingComplianceForm";

export const formList = {
  HOME: HomePageForm,
  "ABOUT-US": AboutUsPageForm,
  HEADER: HeaderForm,
  FOOTER: FooterForm,
  "TERM-AND-CONDITION": TermConditionsForm,
  "PRIVACY-POLICY": PrivacyPolicyForm,
  "CONTACT-US": ContactUsForm,
  "VERIFIED-INSTALLER": VerifiedInstallerForm,
  "FOR-WHOLESALERS": ForWholeSalerForms,
  "CORE-REFUND-POLICY": CoreRefundPolicy,
  "WARRANTY-TERMS": WarrantyTermsForm,
  "LICENSING-COMPLIANCE": LicensingComplianceForm,
};
