import React from "react";
import { Form, Input, Popover, Upload } from "antd";
import UploadUtils from "../../utilities/uploadUtils";
import SortDraggableList from "../SortDraggableList";
import { QuestionCircleOutlined } from "@ant-design/icons";

const SortableWholesalerBenefit = ({ data, setData, form }) => {
  const benefitItems =
    data?.wholesalers_benefits?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderBenefitContent = (field) => (
    <div>
      <Form.Item
        label="Benefit Name"
        name={[field.name, "benefit_name"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={
          <span>
            Benefit Image{" "}
            <Popover
              placement="right"
              title="W * H"
              content="40 x 40"
            >
              <QuestionCircleOutlined />
            </Popover>
          </span>
        }
        name={[field.name, "benefit_image"]}
        {...UploadUtils.formItemProps}
        wrapperCol={{ span: 24 }}
      >
        <Upload {...UploadUtils.buttonPreviewProps}>
          <UploadUtils.CardContent />
        </Upload>
      </Form.Item>
    </div>
  );

  return (
    <SortDraggableList
      items={benefitItems}
      renderContent={renderBenefitContent}
      setData={setData}
      form={form}
      formField="wholesalers_benefits"
      addContent={false}
      showRemoveOption={false}
      cardTitle="Benefit"
    />
  );
};

export default SortableWholesalerBenefit;
