import React, { useState } from "react";
import { Card, Button, Form } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const SortableItem = ({
  id,
  index,
  renderContent,
  remove,
  showRemoveOption,
  cardTitle,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, index });

  return (
    <Card
      size="small"
      title={`${cardTitle} ${index + 1}`}
      ref={setNodeRef}
      style={{
        transform: `translate3d(${transform?.x || 0}px, ${
          transform?.y || 0
        }px, 0)`,
        cursor: "-moz-grab",
        transition,
        ...attributes.style,
      }}
      {...attributes}
      {...listeners}
      hoverable={true}
      extra={
        showRemoveOption && (
          <MinusCircleOutlined onClick={() => remove(index)} />
        )
      }
    >
      {renderContent()}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <i className="ri-drag-drop-line" style={{ fontSize: "18px" }}></i>
      </div>
    </Card>
  );
};

const SortDraggableList = ({
  items,
  renderContent,
  setData,
  form,
  formField,
  addContent,
  showRemoveOption,
  cardTitle,
}) => {
  // const [showNewItem, setShowNewItem] = useState(false);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 10 },
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item?.id === active?.id);
      const newIndex = items.findIndex((item) => item?.id === over?.id);

      if (oldIndex !== -1 && newIndex !== -1) {
        const updatedItems = arrayMove(items, oldIndex, newIndex);
        setData((prevData) => ({
          ...prevData,
          [formField]: updatedItems.map(({ id, ...rest }) => rest),
        }));

        form.setFieldsValue({
          [formField]: updatedItems.map(({ id, ...rest }) => rest),
        });
      }
    }
  };

  const handleAdd = (add) => {
    // setShowNewItem(true);
    add();
  };

  return (
    <Card size="small" style={{ backgroundColor: "#fafafa" }}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={items?.map((item) => item.id)}
          strategy={verticalListSortingStrategy}
        >
          <Form.List name={formField}>
            {(fields, { add, remove }) => (
              <div
                style={{ display: "flex", flexDirection: "column", gap: 20 }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {fields?.map((field, index) => (
                    <SortableItem
                      key={items[index]?.id}
                      id={items[index]?.id}
                      index={index}
                      renderContent={() => renderContent(field, index)}
                      remove={remove}
                      showRemoveOption={showRemoveOption}
                      cardTitle={cardTitle}
                    />
                  ))}
                  {/* {showNewItem && (
                    <SortableItem
                      key="new-item"
                      id="new-item"
                      index={fields.length}
                      renderContent={() =>
                        renderContent({ name: fields.length })
                      }
                      remove={remove}
                      showRemoveOption={showRemoveOption}
                      cardTitle={cardTitle}
                    />
                  )} */}
                </div>
                {addContent && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => handleAdd(add)}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add {cardTitle}
                    </Button>
                  </Form.Item>
                )}
              </div>
            )}
          </Form.List>
        </SortableContext>
      </DndContext>
    </Card>
  );
};

export default SortDraggableList;
