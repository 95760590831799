import "react-quill/dist/quill.snow.css";
import "antd/dist/reset.css";
import "./App.css";

import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Link,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { ConfigProvider, Result, Spin, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "remixicon/fonts/remixicon.css";

import withUserContext, { UserContext } from "./contexts/user";
import routes from "./constants/routes";
import withHelmet from "./utilities/withHelmet";
import withSidebar from "./utilities/withSidebar";
import themeConfig from "./config/theme.json";
import { Auth0Provider } from "@auth0/auth0-react";
import { ErrorBoundary } from "react-error-boundary";
import { Suspense } from "react";

function ErrorComponent({ error, resetErrorBoundary }) {
  return (
    <Result
      status="500"
      title="500"
      subTitle="Oops, something went wrong with our engine. Our apologies, we're on it!"
      extra={
        <Button onClick={() => (window.location.href = "/")} type="primary">
          Take me back to the dashboard
        </Button>
      }
    />
  );
}

function App() {
  Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: 24 }} spin />);
  return (
    <ConfigProvider theme={themeConfig}>
      <ErrorBoundary FallbackComponent={ErrorComponent}>
        {/* <Alert.ErrorBoundary> */}
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            prompt: "login",
            redirect_uri: `${process.env.REACT_APP_WEBSITE_URL}${routes.LOGIN.path}`
          }}
        >
        <Suspense fallback={<div className="suspenece-spin"><Spin /></div>}>
          <UserContext.Consumer>
            {(user) => {
              if (user.status === "unknown" || user.status === "loading") {
                return (
                  <div
                    style={{
                      width: "100vw",
                      height: "100vh",
                      background: "#fff",
                    }}
                  ></div>
                );
              }
              return (
                <RouterProvider
                  router={createBrowserRouter(
                    createRoutesFromElements(
                      <>
                        {Object.values(routes).map((route, index) => {
                          if (user.status === "loggedin") {
                            if (route.allowAccessWhenLoggedIn === false) {
                              return (
                                <Route
                                  path={route.path}
                                  element={<Navigate to="/" replace={true} />}
                                  key={index}
                                />
                              );
                            } else {
                              return (
                                <Route
                                  path={route.path}
                                  Component={withHelmet(
                                    route.pageTitle,
                                    route.withSidebar
                                      ? withSidebar(route.component)
                                      : route.component
                                  )}
                                  key={index}
                                />
                              );
                            }
                          } else if (route.requireLogin === false) {
                            return (
                              <Route
                                path={route.path}
                                Component={withHelmet(
                                  route.pageTitle,
                                  route.withSidebar
                                    ? withSidebar(route.component)
                                    : route.component
                                )}
                                key={index}
                              />
                            );
                          } else {
                            return (
                              <Route
                                path={route.path}
                                element={
                                  <Navigate to="/login" replace={true} />
                                }
                                key={index}
                              />
                            );
                          }
                        })}
                      </>
                    )
                  )}
                />
              );
            }}
          </UserContext.Consumer>
        </Suspense>
        </Auth0Provider>
        {/* </Alert.ErrorBoundary> */}
      </ErrorBoundary>
    </ConfigProvider>
  );
}

export default withUserContext(App);
