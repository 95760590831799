import React from "react";
import { DatePicker, Form, Input, InputNumber, Popover, Upload } from "antd";
import UploadUtils from "../../utilities/uploadUtils";
import SortDraggableList from "../SortDraggableList";
import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { TextArea } = Input;

const SortableTestimonial = ({ data, setData, form }) => {
  const testimonialItems =
    data?.testimonials?.map((item, index) => ({
      id: index.toString(),
      ...item,
    })) || [];

  const renderTestimonialContent = (field) => (
    <div>
      <Form.Item
        label="Testimonial Date"
        name={[field.name, "testimonial_date"]}
        wrapperCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <DatePicker
          format={(d) => dayjs(d).format("MMM D, YYYY")}
          disabledDate={(date) => !date.isBefore(dayjs())}
        />
      </Form.Item>
      <Form.Item
        label={
          <span>
            Customer Image{" "}
            <Popover
              placement="right"
              title="W * H"
              content="64 x 64"
            >
              <QuestionCircleOutlined />
            </Popover>
          </span>
        }
        name={[field.name, "customer_image"]}
        {...UploadUtils.formItemProps}
        wrapperCol={{ span: 24 }}
      >
        <Upload {...UploadUtils.buttonPreviewProps}>
          <UploadUtils.CardContent />
        </Upload>
      </Form.Item>
      <Form.Item
        label="Customer Name"
        name={[field.name, "customer_name"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Customer Rating"
        name={[field.name, "customer_rating"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <InputNumber min={0} max={5} />
      </Form.Item>
      <Form.Item
        label="Customer Testimonial"
        name={[field.name, "customer_testimonial"]}
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <TextArea />
      </Form.Item>
    </div>
  );

  return (
    <SortDraggableList
      items={testimonialItems}
      renderContent={renderTestimonialContent}
      setData={setData}
      form={form}
      formField="testimonials"
      addContent={true}
      showRemoveOption={true}
      cardTitle="Testimonial"
    />
  );
};

export default SortableTestimonial;
